import React, { useState } from 'react';

import { Flex } from '@circleci/compass';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { setQueryParam, getQueryParams } from '~/components/shared/Link';
import { mediaMaxWidthMedium } from '~/components/shared/screenSizes';
import linkMessages from '~/links/messages';
import styled from '~/styled';

import EmailAndLegacyVcsLogin from '../EmailAndLegacyVcsLogin';
import SignupForm from '../SignupForm';
import AuthButtonGitlab from './AuthButtonGitlab';

interface AuthButtonProps {
  primaryBtn: string;
  secondaryBtn: string;
  primaryBtnDropdown1: string;
  primaryBtnDropdown2: string;
  gitlabBtn?: string;
  'data-cy'?: string;
  page: 'signup' | 'vcsAuthorized';
}

const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .show-signup-form & {
    &.authwrapper-signup [data-optimizely='email-button'] {
      display: none;
    }
  }
`;

const ButtonsHeading = styled('h2')(({ theme }) => {
  return `
  color: ${theme.colors.cg900};
  font-size: ${theme.fontSizes.h4};
  line-height: 1.3;
  font-weight: ${theme.fontWeights.semiBold};
  margin: 0 0 2rem;
  ${mediaMaxWidthMedium} {
    text-align: left;
  }
`;
});

const LegacyAuthButtons = ({
  primaryBtn,
  secondaryBtn,
  primaryBtnDropdown1,
  primaryBtnDropdown2,
  gitlabBtn,
  page,
  'data-cy': dataCy,
}: AuthButtonProps): React.ReactElement => {
  const [displayWelcomeBackContent, setDisplayWelcomeBackContent] =
    useState(false);

  const intl = useIntl();
  const router = useRouter();
  const queryParams = getQueryParams(router ? router.asPath : '');
  const returnTo = queryParams.get('return-to');
  const query = new Map();

  if (returnTo) {
    query.set('return-to', returnTo);
  }

  if (returnTo?.includes('locked_project')) {
    localStorage.setItem(
      'activation-lockedProjectCampaignReturnToUrl',
      returnTo as string,
    );
  }
  const gitlabPath = intl.formatMessage(linkMessages.authSignUp);
  const isLoginPage = page === 'vcsAuthorized';

  const logInTitle = displayWelcomeBackContent
    ? 'welcomeTitleBtn'
    : 'titleBtns';

  return (
    <AuthWrapper
      data-testid='auth-buttons-legacy'
      data-cy={dataCy}
      className={`authwrapper-${page}`}
    >
      <ButtonsHeading data-testid='log-in-title'>
        {intl.formatMessage({
          id: `vcsAuthorized.hero.${logInTitle}`,
        })}
      </ButtonsHeading>
      {isLoginPage && (
        <EmailAndLegacyVcsLogin
          primaryBtn={primaryBtn}
          secondaryBtn={secondaryBtn}
          primaryBtnDropdown1={primaryBtnDropdown1}
          primaryBtnDropdown2={primaryBtnDropdown2}
          displayWelcomeBackContent={displayWelcomeBackContent}
          setDisplayWelcomeBackContent={setDisplayWelcomeBackContent}
        />
      )}
      {page === 'signup' && <SignupForm />}
      <Flex
        flexDirection={'column'}
        data-button-style='outlined-buttons'
      >
        {page === 'signup' && (
          <AuthButtonGitlab
            textId={gitlabBtn ?? ''}
            destination={setQueryParam(gitlabPath, query)}
          />
        )}
      </Flex>
    </AuthWrapper>
  );
};

export default LegacyAuthButtons;
