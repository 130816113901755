import * as React from 'react';

import { useIntl } from 'react-intl';

import * as colors from '~/components/shared/colors';
import { IntlLink } from '~/components/shared/Link';
import RecaptchaTerms from '~/components/shared/RecaptchaTerms';
import * as screenSizes from '~/components/shared/screenSizes';
import * as fonts from '~/components/shared/typography';
import linkMessages from '~/links/messages';
import styled from '~/styled';

const ParagraphSmall = styled.p`
  line-height: 1.75;
  font-size: ${fonts.sizes.fs400};
  color: ${colors.black100};
  margin-top: 20px;

  ${screenSizes.mediaMaxWidthXtraSmall} {
    text-align: left;
  }

  ${screenSizes.mediaMaxWidthSmall} {
    font-weight: 400;
  }

  .show-signup-form .authwrapper-signup + & {
    margin-top: 40px;
  }
`;

const Disclaimer = ({ page }: { page: string }): React.ReactElement => {
  const intl = useIntl();

  return (
    <ParagraphSmall data-testid='disclaimer-legacy'>
      {intl.formatMessage({
        id: `disclaimer.part1.${page}`,
      })}{' '}
      <IntlLink hrefTranslation={linkMessages.termsOfService}>
        {intl.formatMessage({
          id: 'disclaimer.link1Text',
        })}
      </IntlLink>{' '}
      {intl.formatMessage({
        id: 'disclaimer.part2',
      })}{' '}
      <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
        {intl.formatMessage({
          id: 'disclaimer.link2Text',
        })}
      </IntlLink>
      .
      <RecaptchaTerms />
    </ParagraphSmall>
  );
};

export default Disclaimer;
